<template>
    <div class="contentWrap">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="码客查" name="mkc" v-if="checkPermission(['mkc'])">
          <div>
            <span class="title">小程序id：</span>
            <el-button type="text" size="mini" class="tag-read" @click="copyLink('wx71e5aa098fae6c5d')"
              :data-clipboard-text="Link">复制链接
            </el-button>
          </div>
          
           <div>
            <div v-if="haveShop">
              <span class="title">商城链接：</span>
              <el-button type="text" size="mini" class="tag-read"
                @click="copyLink('pages/products/main?companyId=' + companyId)" :data-clipboard-text="Link">复制链接
              </el-button>
            </div>
            <div>
              <span class="title">模版链接：</span><span>请查看标识页面列表</span>
            </div>
            <div v-if="haveShop">
              <span class="title">商品链接：</span><span>请查看商品列表</span>
            </div>
          </div>

        </el-tab-pane>
        <el-tab-pane label="码客通名片" name="mkt" v-if="checkPermission(['mkt'])">
          <div><span class="title">小程序id：</span>
            <el-button type="text" size="mini" class="tag-read" @click="copyLink('wx19adaa0665cc0c6a')"
              :data-clipboard-text="Link">复制链接
            </el-button>
          </div>
          <div>
            <div>
              <span class="title">商城链接：</span>
              <el-button type="text" size="mini" class="tag-read"
                @click="copyLink('pages/Products/main?companyId=' + companyId)" :data-clipboard-text="Link">复制链接
              </el-button>
            </div>
            <div>
              <span class="title">商品链接：</span><span>请查看商品列表</span>
            </div>
          </div>
          
        </el-tab-pane>
      </el-tabs>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
import {checkPermission} from "@/directives/permission";
import {mapState, mapMutations, mapGetters} from 'vuex';
export default {
  data() {
    return {
      companyId: null,
      Link: null,
      info: {},
      activeName: '',
      tabs: [],
      haveShop: false
    }
  },
  created() {
    this.companyId = JSON.parse(localStorage.getItem('info')).companyId
    this.info = JSON.parse(localStorage.getItem('info'));

    // // 动态tab栏
    // let tabs = JSON.parse(localStorage.getItem('tabs'))
    // if(tabs.length) {
    //   this.tabs = tabs.map(item => item.label)
    //    if (this.tabs.indexOf("码客查") !== -1){
    //       this.activeName = '0'
    //   }
    //   if (this.tabs.indexOf("码客通名片") !== -1){
    //       this.activeName = '1'
    //       return
    //   }
         
    // }else {
    //   this.tabs = []
    // }
  },
  computed: {
    ...mapState(['btnList']),
    ...mapGetters([
      'asyncMenus'
    ]),
  },
  mounted() {
    var tabArray=['mkt','mkc']
    var arr = this.asyncMenus.map(item => item.meta.permission)
    this.activeName=this.btnList.find((item)=>tabArray.includes(item))
    this.haveShop = arr.includes('good')
    console.log('haveShop', this.haveShop)
  },
  methods: {
    checkPermission,
    handleClick(tab, event) {
      let id = event.target.id
      if(id === 'tab-mkt') {
        this.activeName="mkt"
      }else {
        this.activeName="mkc"
      }
    },
    copyLink(data) {
      this.Link = data // data.customPageId
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        console.log('复制成功')
        //  释放内存

        // clipboard.destory()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        // clipboard.listener.destory()
      })
    }
  }


}
</script>
<style scoped>
.tip {
  color: #f00;
  line-height: 30px;
}

.contentWrap {
  background: #fff;
  line-height: 40px;
  font-size: 14px;
  color: #333;
  padding-top: 20px;
  height: 90vh;
}

.contentWrap .title {
  font-weight: bold;
  padding-left: 40px;
  padding-top: 20px;
}
</style>